* {
  box-sizing: border-box;
  color: rgb(58, 58, 58);
  font-family: "Montserrat", sans-serif;
  margin: 0;
  font-weight: 400;
}

.portfolio-card img {
  inline-size: 90%;
}

/* .about-intro {
  padding: 15px;
} */

.App {
  background-color: rgba(58, 58, 58, 0.032);
  block-size: 100vh;
}

@media (min-width: 1024px) {
  * {
    text-align: start;
  }
}

.navbar-container {
  background-color: white;
  border-block-end: 2px solid rgb(58, 58, 58);
  display: flex;
  justify-content: flex-end;
  align-items: center;

  gap: 10px;
  padding: 0px 15px 0px 0px;

  text-align: center;

  inline-size: 100%;
}

.footer-container {
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  inline-size: 100%;
}

.contacts-container {
  display: flex;
  gap: 20px;
  justify-content: center;
  background-color: rgba(58, 58, 58, 0);
  /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 10px;
  padding: 5px 20px;
}

.home-link {
  margin-inline-end: auto;
}

.hero-content {
  color: white;
  position: absolute;
  top: 20%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  inline-size: 100%;
}

.hero-btn {
  background-color: #dd7973;
  font-size: 1.1rem;
  inline-size: 25%;
  margin: auto;
  font-style: none;
  border: 2px solid #dd7973;
  border-radius: 4px;
  font-weight: bold;
  margin-block-end: 40px;
  display: flex;
  justify-content: center;
}

.hero-btn-inner {
  inline-size: 100%;
  color: white;
  padding: 5px;
  text-align: center;
}

.hero-btn:hover {
  transform: scale(1.1);
  background-color: #d44c45;
  border-color: #d44c45;
}

.hero-title {
  font-family: "Playball", cursive;
  font-weight: bold;
  font-size: 4rem;
  margin: 0;
  margin-inline-start: 15px;
  animation: headerContentH1 2s ease-out;
}

.hero-subtitle {
  font-size: 1.3rem;
  margin-block-start: 10px;
  margin-inline-start: 15px;
  animation: headerContentH2 2s ease-out;
  transform: translateX(100vw);
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  margin-block-end: 75px;
}

.hero-status {
  margin-block-start: 0;
  margin-block-end: 20px;
  font-size: 1rem;

  transform: translateX(100vw);
  animation: headerContentStatus 10s linear infinite;
  animation-delay: 2s;
}

@media (min-width: 768px) {
  .hero-title {
    font-size: 5.5rem;
  }

  .hero-subtitle {
    font-size: 1.7rem;
    margin-block-end: 100px;
  }
}

@media (min-width: 1024px) {
  .hero-title {
    font-size: 7.5rem;
  }

  .hero-subtitle {
    font-size: 2.3rem;
    margin-block-end: 150px;
    font-weight: 300;
  }

  .hero-btn {
    inline-size: 15%;
  }

  .hero-btn-inner {
    padding: 10px 20px;
  }

  .hero-status {
    font-size: 1.2rem;
    animation: headerContentStatus 15s linear infinite;
  }
}

@media (min-width: 1440px) {
  .hero-title {
    font-size: 12rem;
  }

  .hero-subtitle {
    font-size: 3rem;
    margin-block-end: 150px;
    font-weight: 300;
  }

  .hero-btn {
    inline-size: 15%;
  }

  .hero-btn-inner {
    padding: 10px 20px;
  }

  .hero-status {
    font-size: 1.5rem;
    animation: headerContentStatus 15s linear infinite;
  }
}

@keyframes headerContentH1 {
  from {
    transform: translateX(-100vw);
  }
  to {
    transform: translateX(0vw);
  }
}

@keyframes headerContentH2 {
  from {
    transform: translateX(100vw);
  }
  to {
    transform: translateX(0vw);
  }
}

@keyframes headerContentH3 {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}

@keyframes headerContentStatus {
  0% {
    transform: translateX(100vw);
  }
  50% {
    transform: translateX(0vw);
  }
  100% {
    transform: translateX(-100vw);
  }
}

.portfolio-title,
.skills-title {
  text-align: center;

  font-size: 2.5rem;
  border-block-end: 2px solid rgb(58, 58, 58);

  font-weight: 700;
  inline-size: 100vw;
  padding: 30px 0;
  background-color: white;
}

.portfolio-container {
  min-block-size: 100vh;
}

.portfolio-card-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  justify-content: space-between;
  text-align: center;
  padding: 20px 10px;
  gap: 20px;
  padding-block-end: 65px;
  font-weight: 500;
}

.portfolio-card {
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 2px rgba(58, 58, 58, 0.132);
}

.portfolio-card:nth-child(even) {
  flex-direction: row-reverse;
}

.portfolio-card img {
  border-radius: 5px;
  margin-block-end: 10px;
}

.portfolio-card-text h3 {
  font-weight: 700;
  font-size: 1.6rem;
  margin: 20px 0px;
}
.portfolio-card-text p {
  font-weight: 400;
  margin-block-end: 10px;
}

.portfolio-card-btns {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.portfolio-card-btns a {
  font-weight: 400;
  font-size: 1.1rem;
  background-color: #dd7973;
  text-decoration: none;
  padding: 10px 15px;
  /* background-color: ; */
  border: 2px solid #dd7973;
  color: white;
  border-radius: 5px;
}

.portfolio-card-btns a:hover {
  transform: scale(1.1);
  background-color: #d44c45;
  border-color: #d44c45;
}

@media (min-width: 768px) {
  .portfolio-card-container {
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    inline-size: 90%;
    margin: auto;
  }
  .portfolio-card {
    inline-size: 45%;
  }

  .portfolio-card img {
    inline-size: 90%;
  }
}

@media (min-width: 1024px) {
  .portfolio-card-container {
    margin-block-start: 30px;
    flex-direction: column;
    inline-size: 75%;
    gap: 50px;
  }
  .portfolio-card {
    inline-size: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 25px; */
    gap: 10px;
  }
  .portfolio-card img {
    size: 100px;
    flex: 1;
    margin: 0;
    inline-size: 50%;
  }

  .portfolio-card-text {
    flex: 1;
    font-size: 1rem;
  }
}

@media (min-width: 1440px) {
  .portfolio-card-container {
    margin-block-start: 55px;

    flex-direction: column;
    inline-size: 60%;
    gap: 75px;
  }
  .portfolio-card {
    inline-size: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /* .portfolio-card img {
    size: 100px;
    flex: 1;
  } */

  .portfolio-card-text {
    flex: 1;
    font-size: 1rem;
  }
}

.skills-card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
  padding: 30px;
  padding-block-end: 75px;
  text-align: center;
}

.skills-card {
  background-color: white;
  padding: 10px;
  border-radius: 5%;
  box-shadow: 0 0 5px 1px grey;
  inline-size: 30%;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.skills-card img {
  inline-size: 50%;
}

.skills-card h3 {
  font-size: 1rem;
}

@media (min-width: 768px) {
  .skills-card-container {
    padding: 25px 100px 75px 100px;
    gap: 20px;
  }
}

@media (min-width: 1024px) {
  .skills-card-container {
    padding: 25px 150px 75px 150px;
    gap: 20px;
  }

  .skills-card {
    inline-size: 20%;
  }
}

@media (min-width: 1440px) {
  .skills-card-container {
    padding: 25px 250px 75px 250px;
    gap: 20px;
  }
}

.about-container {
  padding: 0px 15px 15px 15px;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  inline-size: 100vw;
  position: absolute;
  top: 15%;
  background-color: white;
  border-block-start: 2px solid rgb(58, 58, 58);
  border-block-end: 2px solid rgb(58, 58, 58);
}

.about-intro {
  font-size: 1.2rem;
  margin-block-end: 20px;
  font-weight: 500;
}

.about-email {
  font-weight: 700;
  text-decoration: underline;
  margin-block-start: 5px;
}

@media (min-width: 768px) {
  .about-container {
    padding: 75px;
    top: 20%;
  }
  .about-intro {
    font-size: 1.4rem;
  }
}

@media (min-width: 1024px) {
  .about-container {
    padding: 20px 120px 50px 120px;
    top: 20%;
  }
  .about-intro {
    font-size: 1.4rem;
  }
}

@media (min-width: 1440px) {
  .about-container {
    padding: 20px 300px 100px 300px;
    top: 20%;
  }
  .about-intro {
    font-size: 1.6rem;
    /* padding: 0px 200px; */
  }
}

@media (min-width: 2560px) {
  .about-container {
    padding: 0px 500px;
  }
  .about-intro {
    font-size: 1.8rem;
  }
}
